<template>
  <div id="infoDetail">
    <el-row class="detailPage">
      <el-col :span="16">
        <div class="detailPage__leftBox">
          <div class="detailPage__leftBox--newsInfo">
            <label>新聞標題：<strong>{{ detailList.NewsTitle }}</strong></label>
            <el-row style="width:100%;font-size:1.1rem;">
              <el-col :span="12">
                <label>新聞來源：<strong>{{ detailList.NewsAuthor }}</strong></label>
              </el-col>
              <el-col :span="12">
                <label>新聞時間：<strong>{{detailList.NewsTime | moment("YYYY-MM-DD HH:mm")}}</strong></label>
              </el-col>
            </el-row>
          </div>
          <div class="detailPage__leftBox--newsContent">
            <!-- 高亮操作 -->
            <div class="markKeyWord">
              <div class="markKeyWord__markIcon">
                <el-tooltip effect="dark" content="人名" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('titlePeople')" icon="user"/> 
                </el-tooltip>

                <el-tooltip effect="dark" content="組織" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('org')" icon="building"/> 
                </el-tooltip>

                <el-tooltip effect="dark" content="地點" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('place')" icon="map-marker-alt"/>
                </el-tooltip>

                <el-tooltip effect="dark" content="國家" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('nation')" icon="flag"/>
                </el-tooltip>
              </div>
            </div>
            <!-- 新聞詳細內文 -->
            <text-highlight :queries="queries" :class="hightLightClassStyle">
              {{ newsDescription }}
            </text-highlight>
          </div>
        </div>
      </el-col>
      
      <!-- 關鍵字 -->
      <el-col :span="8">
        <div class="detailPage__rightBox">
          <div class="detailPage__rightBox--tagBox">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item title="人名" name="1" class="item1">
                <div class="d-flex" v-if="TitlePeople.length > 0">
                  <el-tag v-for="(item, index) in TitlePeople" :key="index">
                    <span v-if="isOperator" @click="addWarningForm(item,'people','add')"> {{ item }}</span>
                    <span v-else>{{ item }}</span>
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p>
              </el-collapse-item>

              <el-collapse-item title="組織" name="2" class="item2">
                <div class="d-flex" v-if="Org.length > 0">
                  <el-tag v-for="(item, index) in Org" :key="index">
                    <span v-if="isOperator" @click="addWarningForm(item,'org','add')"> {{ item }}</span>
                    <span v-else>{{ item }}</span>
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p>
              </el-collapse-item>

              <el-collapse-item title="地點" name="3" class="item3">
                <div class="d-flex" v-if="Place.length > 0">
                  <el-tag v-for="(item, index) in Place" :key="index">
                    {{ item }}
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p> 
              </el-collapse-item>

              <el-collapse-item title="國家" name="4" class="item4">
                <div class="d-flex" v-if="Nation.length > 0">
                  <el-tag v-for="(item, index) in Nation" :key="index">
                    <span> {{ item }}</span>
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p>
              </el-collapse-item>

            </el-collapse>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <div class="detailPage__footer">
          <el-button type="primary" @click="toggleDialog">建立關注名單（暫保留，直接連結開啟關注名單新建頁面）</el-button>
          <el-button type="danger" @click="editRelatedNews">加入分析專卷（原加入關聯資訊，沒有相似功能）</el-button>
          <!-- <el-button type="primary" @click="toggleDialog">建立警示名單</el-button> -->
          <!-- <el-button type="danger" @click="editRelatedNews">加入關聯資訊</el-button> -->
          <el-button type="info" @click="closeDetail">關閉</el-button>
        </div>
      </el-col>
    </el-row>

    <div v-if="openPopUp" class="WarningListPopUp">
      <div class="popup">
        <i class="el-icon-close" @click="toggleDialog"></i>
        <p>請選擇要新增的名單類別</p>
        <div>
          <el-button type="danger" @click="addWarningFormOnlyNews('people')">人名</el-button>
          <el-button type="danger" @click="addWarningFormOnlyNews('org')">組織</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openPopUp: false,
      userRole: this.$store.state.userInfo.Role,
      newsDescription:"",//新聞內文
      queries: [],//欲高亮的字串
      Nation: [],//國家
      Place: [],//地點
      TitlePeople: [],//人名
      Org: [],//組織
      activeName: "1",
      detailList: {},
      hightLightClassStyle: "",
      listQuery:{
        DataID: [this.$route.query.DataID],
        Page_Size: 1,
        Page_Num: 1,
        IsUser:true
      },
      manageAllSelection:{
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      }
    };
  },
  computed:{
    isOperator(){
      let result = null;
      if(this.userRole==='operator'){
        result = true
      }else{
         result = false
      }
      return result
    },
  },
  methods: {
    addWarningFormOnlyNews(formType) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:'add',
          warningFormType: formType,//people或 org
          typeNews: 'searchNews',
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    toggleDialog() {
      this.openPopUp = !this.openPopUp;
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      this.$api.data.searchNews(this.listQuery).then((res) => {
        this.detailList = res.data[0];
        this.newsDescription = res.data[0].NewsContent
        this.manageAllSelection.searchNews.push(res.data[0])
        this.setTagTitle(res);
        this.$store.dispatch("loadingHandler", false);
      });
    },
    // 處理右側標籤資料
    setTagTitle(res) {
      this.Nation = res.data[0].CtyName == "" ? [] : res.data[0].CtyName;
      this.Place = res.data[0].LocName == "" ? [] : res.data[0].LocName;
      this.TitlePeople = res.data[0].PplName == "" ? [] : res.data[0].PplName;
      this.Org = res.data[0].ComName == "" ? [] : res.data[0].ComName;
    },
    // 文字高亮
    handleTextHighlight(tagName) {
      let hightLightText = "";
      switch (tagName) {
        case "nation":
          hightLightText = this.Nation;
          break;
        case "place":
          hightLightText = this.Place;
          break;
        case "titlePeople":
          hightLightText = this.TitlePeople;
          break;
        case "org":
          hightLightText = this.Org;
          break;
      }
      this.queries = hightLightText;
      this.hightLightClassStyle = tagName;
    },
    addWarningForm(item,formType,actionsType) {
      this.$confirm(`確定要將 ${item} 加到警示名單?`, "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then((action)=>{
        if (action === 'confirm') {
          this.$router.push({
            name: "warningListAdd",
            query: {
              caseStatus:actionsType,
              warningFormType: formType,//people或 org
              typeNews: 'searchNews',
              DataID:JSON.stringify(this.manageAllSelection),
              routerName:'detailNews',
              tagName:item,
            },
          });
          done();
        } 
      })
      .catch((action)=>{
        if(action==='cancel'){
          return
        }
      })
    },
    /* 加入關聯新聞編輯 */
    editRelatedNews() {
      this.$router.push({
        name: "relatedNews",
        query: {
          caseStatus:'',
          warningFormType: 'people',//people或 org
          typeNews: 'searchNews',
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    closeDetail() {
      this.$confirm("確定要關閉此頁面嗎？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        window.close();
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scope>
.fa-shadow {
  text-shadow: 3px 3px rgba(255, 165, 0, 0.75);
}
#infoDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 1024px;
  padding: 3% 8%;
  background: linear-gradient(45deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  box-sizing: border-box;

  .detailPage {
    &__leftBox {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;

      &--newsInfo {
        width: 100%;
        display: flex;
        font-size: 1.3rem;
        margin-bottom: 30px;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        label {
          padding-bottom: 8px;
        }
      }

      &--newsContent {
        width: 100%;
        padding: 18px;
        font-size: 1.25rem;
        line-height: 35px;
        box-sizing: border-box;
        min-height: 300px;

        overflow-y: auto;
        border: 1px solid lightgreen;
        box-sizing: border-box;

        p {
          margin: 0;
          letter-spacing: 1.6px;
          line-height: 1.6rem;
        }

        .markKeyWord {
          width: 100%;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &__markIcon {
            padding: 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
              padding: 0 8px;
              transition: 0.4s;
              cursor: pointer;

              &:nth-child(1) {
                color: #4dffff;
                filter: drop-shadow(2px 2px 1px grey);
              }
              &:nth-child(2) {
                color: #79ff79;
                filter: drop-shadow(2px 2px 1px grey);
              }
              &:nth-child(3) {
                color: #ffe66f;
                filter: drop-shadow(2px 2px 1px grey);
              }
              &:nth-child(4) {
                color: #ff95ca;
                filter: drop-shadow(2px 2px 1px grey);
              }

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }

        .titlePeople > mark {
          background: rgb(187, 255, 255) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
        .org > mark {
          background: rgb(187, 255, 187) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
        .place > mark {
          background: rgb(255, 255, 206) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
        .nation > mark {
          background: rgb(255, 217, 236) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }

    &__rightBox {
      width: 100%;
      padding: 95px 8px 8px 8px;
      box-sizing: border-box;

      &--tagBox {
        width: 100%;
        border: 1px solid #eee;
        border-radius: 4px;
        margin-bottom: 15px;
        margin-top: 15px;
        box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5);

        &:last-child {
          margin: 0;
        }

        .header {
          width: 100%;
          padding: 8px;
          box-sizing: border-box;
          border-bottom: 3px solid #eee;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #191970;

          i {
            padding: 4px 8px;
            font-weight: bold;
            color: #191970;
            transition: 0.4s;
            cursor: pointer;

            &:hover {
              transform: scale(1.3);
            }
          }
        }

        .body {
          width: 100%;
          padding: 16px;
          box-sizing: border-box;

          .el-tag {
            margin: 0 4px 4px 0;

            &:last-child {
              margin-right: 0;
            }
          }

          &__noData {
            padding-bottom: 4px;
            margin: 0;
            font-size: 13px;
          }
        }
        .noData {
          margin: 0 16px;
          font-size: 16px;
        }
        .item1 {
          .el-collapse-item {
            padding-bottom: 0;
            &__header {
              background: #bbffff;
              padding: 0 16px;
            }
          }
        }
        .item2 {
          .el-collapse-item {
            &__header {
              background: #bbffbb;
              padding: 0 16px;
            }
          }
        }
        .item3 {
          .el-collapse-item {
            &__header {
              background: #ffffce;
              padding: 0 16px;
            }
          }
        }
        .item4 {
          .el-collapse-item {
            &__header {
              background: #ffd9ec;
              padding: 0 16px;
            }
          }
        }

        .d-flex {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-right: 4px;
          .el-tag {
            margin: 8px;
            &--light {
              cursor: pointer;
            }
          }
        }
      }

      &--tag {
        padding: 4px 0;
        .collapseTag {
          padding: 4px 8px;
          background: #eee;
          border-radius: 10px;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__footer {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .WarningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
</style>
