var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "infoDetail" } },
    [
      _c(
        "el-row",
        { staticClass: "detailPage" },
        [
          _c("el-col", { attrs: { span: 16 } }, [
            _c("div", { staticClass: "detailPage__leftBox" }, [
              _c(
                "div",
                { staticClass: "detailPage__leftBox--newsInfo" },
                [
                  _c("label", [
                    _vm._v("新聞標題："),
                    _c("strong", [_vm._v(_vm._s(_vm.detailList.NewsTitle))])
                  ]),
                  _c(
                    "el-row",
                    { staticStyle: { width: "100%", "font-size": "1.1rem" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("label", [
                          _vm._v("新聞來源："),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.detailList.NewsAuthor))
                          ])
                        ])
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("label", [
                          _vm._v("新聞時間："),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.detailList.NewsTime,
                                  "YYYY-MM-DD HH:mm"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "detailPage__leftBox--newsContent" },
                [
                  _c("div", { staticClass: "markKeyWord" }, [
                    _c(
                      "div",
                      { staticClass: "markKeyWord__markIcon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "人名",
                              placement: "top"
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "user" },
                              on: {
                                click: function($event) {
                                  return _vm.handleTextHighlight("titlePeople")
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "組織",
                              placement: "top"
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "building" },
                              on: {
                                click: function($event) {
                                  return _vm.handleTextHighlight("org")
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "地點",
                              placement: "top"
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "map-marker-alt" },
                              on: {
                                click: function($event) {
                                  return _vm.handleTextHighlight("place")
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "國家",
                              placement: "top"
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "flag" },
                              on: {
                                click: function($event) {
                                  return _vm.handleTextHighlight("nation")
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "text-highlight",
                    {
                      class: _vm.hightLightClassStyle,
                      attrs: { queries: _vm.queries }
                    },
                    [_vm._v(" " + _vm._s(_vm.newsDescription) + " ")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "detailPage__rightBox" }, [
              _c(
                "div",
                { staticClass: "detailPage__rightBox--tagBox" },
                [
                  _c(
                    "el-collapse",
                    {
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "item1",
                          attrs: { title: "人名", name: "1" }
                        },
                        [
                          _vm.TitlePeople.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                _vm._l(_vm.TitlePeople, function(item, index) {
                                  return _c("el-tag", { key: index }, [
                                    _vm.isOperator
                                      ? _c(
                                          "span",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.addWarningForm(
                                                  item,
                                                  "people",
                                                  "add"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(item))]
                                        )
                                      : _c("span", [_vm._v(_vm._s(item))])
                                  ])
                                }),
                                1
                              )
                            : _c("p", { staticClass: "noData" }, [
                                _vm._v("無資料")
                              ])
                        ]
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "item2",
                          attrs: { title: "組織", name: "2" }
                        },
                        [
                          _vm.Org.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                _vm._l(_vm.Org, function(item, index) {
                                  return _c("el-tag", { key: index }, [
                                    _vm.isOperator
                                      ? _c(
                                          "span",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.addWarningForm(
                                                  item,
                                                  "org",
                                                  "add"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(item))]
                                        )
                                      : _c("span", [_vm._v(_vm._s(item))])
                                  ])
                                }),
                                1
                              )
                            : _c("p", { staticClass: "noData" }, [
                                _vm._v("無資料")
                              ])
                        ]
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "item3",
                          attrs: { title: "地點", name: "3" }
                        },
                        [
                          _vm.Place.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                _vm._l(_vm.Place, function(item, index) {
                                  return _c("el-tag", { key: index }, [
                                    _vm._v(" " + _vm._s(item) + " ")
                                  ])
                                }),
                                1
                              )
                            : _c("p", { staticClass: "noData" }, [
                                _vm._v("無資料")
                              ])
                        ]
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "item4",
                          attrs: { title: "國家", name: "4" }
                        },
                        [
                          _vm.Nation.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                _vm._l(_vm.Nation, function(item, index) {
                                  return _c("el-tag", { key: index }, [
                                    _c("span", [_vm._v(" " + _vm._s(item))])
                                  ])
                                }),
                                1
                              )
                            : _c("p", { staticClass: "noData" }, [
                                _vm._v("無資料")
                              ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c(
              "div",
              { staticClass: "detailPage__footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.toggleDialog }
                  },
                  [
                    _vm._v(
                      "建立關注名單（暫保留，直接連結開啟關注名單新建頁面）"
                    )
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: { click: _vm.editRelatedNews }
                  },
                  [_vm._v("加入分析專卷（原加入關聯資訊，沒有相似功能）")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "info" }, on: { click: _vm.closeDetail } },
                  [_vm._v("關閉")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.openPopUp
        ? _c("div", { staticClass: "WarningListPopUp" }, [
            _c("div", { staticClass: "popup" }, [
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.toggleDialog }
              }),
              _c("p", [_vm._v("請選擇要新增的名單類別")]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.addWarningFormOnlyNews("people")
                        }
                      }
                    },
                    [_vm._v("人名")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.addWarningFormOnlyNews("org")
                        }
                      }
                    },
                    [_vm._v("組織")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }