import { render, staticRenderFns } from "./detailNews.vue?vue&type=template&id=60ac2b44&"
import script from "./detailNews.vue?vue&type=script&lang=js&"
export * from "./detailNews.vue?vue&type=script&lang=js&"
import style0 from "./detailNews.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kennyliao/Desktop/Peter/BIA/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60ac2b44')) {
      api.createRecord('60ac2b44', component.options)
    } else {
      api.reload('60ac2b44', component.options)
    }
    module.hot.accept("./detailNews.vue?vue&type=template&id=60ac2b44&", function () {
      api.rerender('60ac2b44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/detailNews.vue"
export default component.exports